<template>
  <div class="home min-h-full">
    <button @click="button" class="p-4 rounded-lg bg-gray-900 text-white">
      CLICK
    </button>
  </div>
</template>

<script>
import { HTTP } from "../helpers/http-common.js";

export default {
  methods: {
    async button() {
      try {
        const res = await HTTP.get("/test");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
